import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyEuro'
})
export class PipeCurrencyEuro implements PipeTransform {

    constructor(@Inject(LOCALE_ID) private locale: string) {}

    transform(value: number,
        digitsInfo: string = '.2-2'
    ): string | null {
        return formatCurrency(
            value,
            this.locale,
            getCurrencySymbol('EUR', 'wide'),
            'EUR',
            digitsInfo,
        );
    }
}
export const environment = {
    envName: 'production',
    production: true,
    apiUrl: 'https://www.mejorquetubanco.com:8443/tuseguro-servicios',
    staticContentPath: 'https://www.mejorquetubanco.com/files',
    emailCompania: 'administracion@astraseguros.com',
    staticUrlHost: 'https://www.mejorquetubanco.com',
    redsysMerchantCode: '14503262',
    redsysIniciaPeticionRest: 'https://sis-t.redsys.es:25443/sis/rest/iniciaPeticionREST',
    redsysTrataPeticionRest: 'https://sis-t.redsys.es:25443/sis/rest/trataPeticionREST',
    tokenSecret: 'Akibu_AA_2021!',

    //id plantillas email
    idPlantillaEmailColectivo: 1,
    idPlantillaEmailCompannia: 2,
    idPlantillaEmailBeneficiarios: 3,
    idPlantillaCarritoAbandonadoInmediato: 4,
    idPlantillaCarritoAbandonado: 5,
    idPlantillaCarritoAbandonadoGestoria: 6,
    idPlantillaEmailEstablecerContrasennia: 7,
    idPlantillaEmailSolicitud: 8,
    idPlantillaEmailGeneralAccionesAgente: 9,
    idPlantillaEmailGeneralAccionesCliente: 10,
    idPlantillaEmailSolicitudFirma: 11,
    idPlantillaIndividualEstablecerContrasennia: 13,
    idPlantillaIndividualCarritoAbandonado24h: 14,
    idPlantillaIndividualCarritoAbandonado: 15,
    idPlantillaEmailIndividualBienvenido: 16,
    idPlantillaEmailCompanniaIndividual: 17,
    idPlantillaEmailBeneficiariosIndividual: 18,
    idPlantillaEmailErrorPago: 19,
    idPlantillaEmailErrorFirmaLogalty: 20,
    idPlantillaEmailContactoUsuario: 21,
    idPlantillaEmailEnfermedadCliente: 22,
};

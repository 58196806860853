<div class="container-modal">
    <div class="header-modal">
        <h1 class="text-color-primary">
            {{ 'politicaPrivacidad.titulo' | translate }}
        </h1>
        <button mat-icon-button (click)="cerrarModalGeneric()" class="btn-icon-cerrar">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <hr>
    <div class="body-modal">
        <div class="privacy-policy-content txt-xs-1">

            <section class="cookies">
                <div [innerHTML]="'politicaPrivacidad.axa' | translate"></div>
            </section>

            <section class="cookies">
                <div [innerHTML]="'politicaPrivacidad.cookies' | translate"></div>
            </section>

            <section class="company-details">
                <div [innerHTML]="'politicaPrivacidad.detallesEmpresa' | translate"></div>
            </section>

            <section class="legal">
                <h3>{{ 'politicaPrivacidad.legal.titulo' | translate }}</h3>
                <div [innerHTML]="'politicaPrivacidad.legal.contenido' | translate"></div>
            </section>

            <section class="responsibility">
                <h3>{{ 'politicaPrivacidad.responsabilidad.titulo' | translate }}</h3>
                <div [innerHTML]="'politicaPrivacidad.responsabilidad.contenido' | translate"></div>
            </section>

            <section class="intellectual-property">
                <h3>{{ 'politicaPrivacidad.propiedadIntelectual.titulo' | translate }}</h3>
                <div [innerHTML]="'politicaPrivacidad.propiedadIntelectual.contenido' | translate"></div>
            </section>
            
            <section class="privacy-policy-content txt-xs-1">
                <h3>{{ 'politicaPrivacidad.proteccionDatos.titulo' | translate }}</h3>
                <div [innerHTML]="'politicaPrivacidad.informacionUsuario.titulo' | translate"></div>
                <div [innerHTML]="'politicaPrivacidad.informacionUsuario.contenido' | translate"></div>
                <div [innerHTML]="'politicaPrivacidad.finTratamiento.titulo' | translate"></div>
                <div [innerHTML]="'politicaPrivacidad.finTratamiento.contenido' | translate"></div>
                <div [innerHTML]="'politicaPrivacidad.publicacionDatos' | translate"></div>
                <section>
                    <h3>{{ 'politicaPrivacidad.derechosUsuario.titulo' | translate }}</h3>
                    <div [innerHTML]="'politicaPrivacidad.derechosUsuario.contenido' | translate"></div>
                </section>
                <div [innerHTML]="'politicaPrivacidad.caracterObligatorio.titulo' | translate"></div>
                <div [innerHTML]="'politicaPrivacidad.caracterObligatorio.contenido' | translate"></div>
            </section>

            <section class="firma-tratamiento">
                <h3>{{ 'politicaPrivacidad.tratamiento-firma.titulo' | translate }}</h3>
                <div [innerHTML]="'politicaPrivacidad.tratamiento-firma.contenido' | translate"></div>
            </section>

        </div>
    </div>
</div>
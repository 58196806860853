<div class="container-modal">
    <div class="header-modal">
        <h1 class="text-color-primary">
            {{ 'informacionLegal.titulo' | translate }}
        </h1>
        <button mat-icon-button (click)="cerrarModalGeneric()" class="btn-icon-cerrar">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <hr>
    <div class="body-modal">
        <div class="privacy-policy-content txt-xs-1">

            <section class="cookies">
                <div [innerHTML]="'politicaPrivacidad.cookies' | translate"></div>
            </section>

            <section class="company-details">
                <div [innerHTML]="'politicaPrivacidad.detallesEmpresa' | translate"></div>
            </section>

            <section class="legal">
                <h3>{{ 'politicaPrivacidad.legal.titulo' | translate }}</h3>
                <div [innerHTML]="'politicaPrivacidad.legal.contenido' | translate"></div>
            </section>

            <section class="responsibility">
                <h3>{{ 'politicaPrivacidad.responsabilidad.titulo' | translate }}</h3>
                <div [innerHTML]="'politicaPrivacidad.responsabilidad.contenido' | translate"></div>
            </section>

            <section class="intellectual-property">
                <h3>{{ 'politicaPrivacidad.propiedadIntelectual.titulo' | translate }}</h3>
                <div [innerHTML]="'politicaPrivacidad.propiedadIntelectual.contenido' | translate"></div>
            </section>

            <section class="legal-information">
                <h3>{{ 'informacionLegal.leyesAplicables.titulo' | translate }}</h3>
                <div [innerHTML]="'informacionLegal.leyesAplicables.contenido' | translate:{ precioMensualLiberty: precioMensualLiberty | currencyEuro, precioMensualAegon: precioMensualAegon | currencyEuro }"></div>
            </section>

        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ConstantesApp } from 'src/app/app.config/constantes-app';
import { ModalPoliticaPrivacidadComponent } from '../../modales/modal-politica-privacidad/modal-politica-privacidad.component';
import { MatDialog } from '@angular/material/dialog';
import { ModalPoliticaCookiesComponent } from '../../modales/modal-politica-cookies/modal-politica-cookies.component';
import { ModalInformacionLegalIndividualComponent } from 'src/app/app.modules/individual/modales/modal-informacion-legal-individual/modal-informacion-legal-individual.component';
import { SesionService } from 'src/app/core.services/sesion.service';
import { Poliza } from 'src/app/app.domain.models/poliza';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    readonly CONSTANTES_APP = ConstantesApp;
    poliza = new Poliza();


    constructor(
        private router: Router,
        public dialog: MatDialog,
        private sesion: SesionService,
    ) { }

    ngOnInit() {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            window.scrollTo(0, 0);
        });

        this.poliza = this.sesion.getPoliza();
    }

    abrirModalInformativo(event: Event) {
        event.preventDefault();
        const dialogRef = this.dialog.open(ModalPoliticaPrivacidadComponent, {
            panelClass: 'custom-modal-info',
            data: { component: ModalPoliticaPrivacidadComponent, mostrarBtnFooter: false },
        });

        dialogRef.afterClosed().subscribe((result) => { });
    }
    
    abrirModalCookies(event: Event) {
        event.preventDefault();
        const dialogRef = this.dialog.open(ModalPoliticaCookiesComponent, {
            panelClass: 'custom-modal-info',
            data: { component: ModalPoliticaCookiesComponent, mostrarBtnFooter: false },
        });

        dialogRef.afterClosed().subscribe((result) => { });
    }
    
    abrirModalInformacionLegal() {
        const dialogRef = this.dialog.open(ModalInformacionLegalIndividualComponent, {
            panelClass: 'modal-informativo',
            data: { component: ModalInformacionLegalIndividualComponent, mostrarBtnFooter: false, poliza: this.poliza },
        });

        dialogRef.afterClosed().subscribe((result) => { });
    }



}

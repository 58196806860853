import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentPortal } from 'ngx-toastr';
@Component({
    selector: 'app-modal-informacion-legal-individual',
    templateUrl: './modal-informacion-legal-individual.component.html',
    // styleUrls: ['../../../modales/modal-informacion-legal/modal-informacion-legal.component.scss'],
})
export class ModalInformacionLegalIndividualComponent {

    portal!: ComponentPortal<any>;
    mostrarBtnFooter: boolean = true;

    precioMensualLiberty!: number;
    precioMensualAegon!: number;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any,

    ) {
        this.precioMensualLiberty = this.data.poliza.precioMensual * 1.3;
        this.precioMensualAegon = this.data.poliza.precioMensual * 1.2;

    }

    ngOnInit() {
        this.mostrarBtnFooter = this.data.mostrarBtnFooter;
    }

    cerrarModalGeneric(): void {
        this.dialogRef.close();
    }

}

import { CalculosPoliza } from '../app.modules/individual/domain.models/calculos-poliza';
import { Archivo } from '../core.models/archivo';
import { ArchivoCliente } from '../core.models/archivoCliente';
import { NamedType } from '../core.models/named-type';
import { getFieldsFromInstance } from '../core.utilidades/graphql-utils';
import { Beneficiario } from './beneficiario';
import { Cliente } from './cliente';
import { Colectivo } from './colectivo';
import { TipoEstado } from './tipo-estado';
import { TipoPeriodicidad } from './tipo-periodicidad';
import { TipoPoliza } from './tipo-poliza';
import { TipoSeguro } from './tipo-seguro';
import { TipoSexo } from './tipo-sexo';

export class Poliza implements NamedType {
    // evita perder el nombre de clase cuando ivy realiza las optimizaciones de produccion
    _className: string = 'Poliza';

    id?: number;

    cliente: Cliente;
    capitalCobertura: number;
    capitalAccidenteFallecimiento: number;
    capitalAccidenteInvalidez: number;
    precioMensual: number;
    precioMensualFallecimiento: number;
    precioMensualFallecimientoAccidente: number;
    precioMensualIncapacidad: number;
    primeraCuota: number;
    titularCuenta: string;
    ibanCuenta: string;
    autorizacionEnvioEmail: boolean;
    beneficiarioList: Beneficiario[];
    tipoPeriodicidad?: TipoPeriodicidad;
    formalizado: boolean;
    emailInmediatoEnviado: boolean;
    emailCarritoAbandonadoEnviado: boolean;
    identificacionVerificada: boolean;
    incluidoFallecimientoAccidente: boolean;
    incluidoInvalidezAccidente: boolean;
    
    tipoPoliza: TipoPoliza;
    fechaCreacion: Date;
    ultimaAccion: Date;
    ultimaPantalla: string;
    numeroPoliza?: string;
    fechaInicioCobertura?: Date;
    fechaRenovacion?: Date;
    activo: boolean;
    fechaFirma?: Date;

    archivoPoliza?: Archivo;
    colectivo?: Colectivo;


    tipoSeguro: TipoSeguro;
    tipoEstado: TipoEstado;

    archivoReciboHipoteca?: Archivo;

    archivoClienteDni?: ArchivoCliente;
    archivoClienteDniReverso?: ArchivoCliente;
    archivoClienteFirma?: ArchivoCliente;

    codigoPromocional: string;
    descuentoPromocional: number;
    descuentoPoliza: number;
    importeDescuentoPromocional: number;
    importeDescuentoPoliza: number;
    descuentoPromocionalAplicado: boolean;
    descuentoPolizaAplicado: boolean;

    numeroTarjeta: string;
    fechaExpiracion: string;
    cvc: string;

    redsysIdOper: string;
    redsysOrder: string;
    redsysAmount: string;
    threeDSServerTransID: string;
    urlPdfFirmar: string;
    guidFirma: string;
    estadoFirma?: number;
    resultadoFirma?: number;

    polizaValida: boolean;
    rechazoIpa: boolean;

    calculosPoliza?: CalculosPoliza;

    archivoSolicitud?: Archivo;
    archivoPolizaSimplificado?: Archivo;
    archivoAstra?: Archivo;
    archivoAstraAnonimo?: Archivo;
    beneficiariosHerederosLegales: boolean;
    
    constructor() {

        this.capitalCobertura = 50000;
        this.capitalAccidenteFallecimiento = 0;
        this.capitalAccidenteInvalidez = 0;
        this.precioMensual = 0;
        this.precioMensualFallecimiento = 0;
        this.precioMensualFallecimientoAccidente = 0;
        this.precioMensualIncapacidad = 0;
        this.primeraCuota = 0;
        this.titularCuenta = '';
        this.ibanCuenta = '';
        this.autorizacionEnvioEmail = false;
        this.beneficiarioList = [];
        this.formalizado = false;
        this.fechaCreacion = new Date();
        this.activo = true;

        this.ultimaAccion = new Date();
        this.ultimaPantalla = '';
        this.emailInmediatoEnviado = false;
        this.emailCarritoAbandonadoEnviado = false;
        this.identificacionVerificada = false;
        this.incluidoFallecimientoAccidente = false;
        this.incluidoInvalidezAccidente = false;
        this.tipoPoliza = new TipoPoliza();
        this.archivoPoliza = new Archivo();
        
        this.cliente = new Cliente();
        this.tipoSeguro = new TipoSeguro();
        this.tipoEstado = new TipoEstado();
        this.archivoReciboHipoteca = new Archivo();

        this.archivoSolicitud = new Archivo();
        this.archivoPolizaSimplificado = new Archivo();
        this.archivoAstra = new Archivo();
        this.archivoAstraAnonimo = new Archivo();

        this.codigoPromocional = '';
        this.descuentoPromocional = 0;
        this.descuentoPoliza = 0;
        this.importeDescuentoPromocional = 0,0;
        this.importeDescuentoPoliza = 0,0;
        this.descuentoPromocionalAplicado = false;
        this.descuentoPolizaAplicado = false;

        this.numeroTarjeta = '';
        this.fechaExpiracion = '';
        this.cvc = '';

        this.redsysIdOper = '';
        this.redsysOrder = '';
        this.redsysAmount = '';
        this.threeDSServerTransID = '';
        this.urlPdfFirmar = '';
        this.guidFirma = '';

        this.polizaValida = true;
        this.rechazoIpa = false;
        this.beneficiariosHerederosLegales = false;
    }
    
    public static getFields = () => {
        const instancia = new Poliza();
        instancia.fechaInicioCobertura = new Date();
        instancia.fechaRenovacion = new Date();
        instancia.numeroPoliza = '';
        instancia.tipoPeriodicidad = new TipoPeriodicidad();
        instancia.beneficiarioList.push(new Beneficiario());
        instancia.cliente = new Cliente();
        instancia.colectivo = new Colectivo();
        instancia.cliente.fechaNacimiento = new Date();
        instancia.cliente.tipoSexo = new TipoSexo();
        instancia.tipoPoliza = new TipoPoliza();
        instancia.archivoClienteFirma = new ArchivoCliente();
        instancia.archivoClienteDni = new ArchivoCliente();
        instancia.archivoClienteDniReverso = new ArchivoCliente();
        instancia.fechaFirma = new Date();
        instancia.estadoFirma = 0;
        instancia.resultadoFirma = 0;
        instancia.calculosPoliza = new CalculosPoliza();
        return getFieldsFromInstance(instancia);
    };
}

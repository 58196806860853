import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ComponentPortal } from 'ngx-toastr';

@Component({
    selector: 'app-modal-politica-privacidad',
    templateUrl: './modal-politica-privacidad.component.html',
    styleUrls: ['./modal-politica-privacidad.component.scss']
})
export class ModalPoliticaPrivacidadComponent {
    portal!: ComponentPortal<any>;
    mostrarBtnFooter: boolean = true;

    constructor(
        public dialogRef: MatDialogRef<any>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.mostrarBtnFooter = this.data.mostrarBtnFooter;
    }

    cerrarModalGeneric(): void {
        this.dialogRef.close();
    }
}

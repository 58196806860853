<div class="container-modal">
    <div class="header-modal">
        <h1 class="text-color-primary">
            {{ 'footer.informacion.cookies' | translate }}
        </h1>
        <button mat-icon-button (click)="cerrarModalGeneric()" class="btn-icon-cerrar">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <hr>
    <div class="body-modal">
        <div class="privacy-policy-content txt-xs-1">
            <section class="cookies">
                <div [innerHTML]="'politica-privacidad.cookies' | translate"></div>
            </section>
        </div>
    </div>
</div>
<div class="layout">
    <!-- Cabecera -->
    <app-header-individual [menuAbierto]="menuAbierto" (reportarEstadoMenu)="actualizarEstadoMenu($event)">
    </app-header-individual>

    <!-- Contenido -->
     <div class="contenido">
         <router-outlet></router-outlet>
     </div>

    <!-- Footer -->
    <app-footer *ngIf="mostrarFooter()"></app-footer>
</div>

<div class="custom-chat-container">
    <span class="custom-chat-text">{{ 'chatra.ayudamos' | translate}} </span>
    <div class="custom-chat-button">
        <img src="../../../../../assets/images/call-center.jpg" alt="Chat" />
    </div>
</div>

import { Component, HostListener, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ConstantesCore } from 'src/app/core.utilidades/constantes-core';
import { HeaderIndividualComponent } from '../header-individual/header-individual.component';
import { Router } from '@angular/router';
import { ConstantesApp } from 'src/app/app.config/constantes-app';

@Component({
    selector: 'app-layout-individual',
    templateUrl: './layout-individual.component.html',
    styleUrls: ['./layout-individual.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LayoutIndividualComponent implements OnInit {

    public pantallaCompleta = false;
    public menuAbierto = true;

    @ViewChild(HeaderIndividualComponent, { read: false, static: false }) headerComponent!: HeaderIndividualComponent;

    constructor(
        private router: Router
    ) { }

    ngOnInit() {
        this.calcularMenuAbiertoCerrado(window.innerWidth);
        this.loadChatra();
    }

    // Escucha los movimientos del navegador
    @HostListener('window:resize', ['$event'])
    onResize(event: { target: { innerWidth: number; }; }) {
        this.calcularMenuAbiertoCerrado(event.target.innerWidth);
    }

    private calcularMenuAbiertoCerrado(ancho: number) {
        // Inicia la visualización del menu inicial segun el ancho de pantalla.
        if (ancho <= ConstantesCore.SIDEBAR_CLOSE_MAX_RESOLUTION) {
            this.pantallaCompleta = true;
            this.menuAbierto = false;
        } else {
            this.pantallaCompleta = false;
            this.menuAbierto = true;
        }
    }

    // recibe el estado del menu desde un hijo y lo sincroniza a ambos hijos.
    public actualizarEstadoMenu(menuAbierto: boolean) {
        this.menuAbierto = menuAbierto;
    }

    public loadChatra() {

        // Configuración de colores
        const buttonTextColor = '#ffffff';

        // Configuración global de Chatra
        (window as any).ChatraID = 'YJepTzieWzpDfD2j3';
        (window as any).ChatraSetup = {
            colors: {
                buttonText: buttonTextColor,
            },
            chatWidth: 400,
            chatHeight: 450,
            buttonSize: 60,
            customWidgetButton: '.custom-chat-button'
        };

        const script = document.createElement('script');
        script.src = 'https://call.chatra.io/chatra.js';
        script.async = true;
        document.head.appendChild(script);
    }

    mostrarFooter(): boolean {
        return this.router.url !== ConstantesApp.PATH_INDIVIDUAL_1_CALCULAR_SEGURO;
    }
}
